import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { arbitrum, fantom, mainnet, polygon } from 'wagmi/chains'
import Data from './layout'
import CustomizedTables from './table'

const chains = [fantom]
const projectId = '32df1398c432bca9546da1e1abce93e3'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)

function App() {
  //<center><CustomizedTables /></center>
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <Data></Data>
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  )
}

export default App;