import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useContractReads, useContractRead, useAccount } from 'wagmi'
import tokABI from './erc20abi.json' 
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#e84b12',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffe0d5',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function CustomizedTables() {
    const [arr, SetDataArray] = useState();

    
      const rows = [
        createData('buyLiquidityFee', arr[0].toString()),
        createData('buyMarketingFee', arr[1].toString()),
        createData('sellLiquidityFee', arr[3].toString()),
        createData('AmountLiquidityFee', arr[5].toString()),
        createData('AmountMarketingFee', arr[6].toString()),
        createData('AmountTokenRewardsFee', arr[7].toString()),
        createData('getClaimWait', arr[2].toString()),
      ];

    const { data, error, isError, isLoading } = useContractReads({
        contracts: [
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'buyLiquidityFee',
                chainId: 250,
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'buyMarketingFee',
                chainId: 250,
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'getClaimWait',
                chainId: 250,
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'sellLiquidityFee',
                chainId: 250,
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'sellMarketingFee',
                chainId: 250,
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'AmountLiquidityFee',
                chainId: 250,
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'AmountMarketingFee',
                chainId: 250,
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'AmountTokenRewardsFee',
                chainId: 250,
            },],
            onSettled(data, error) {
                console.log("DATA*:"+data);
                console.log("ERROR*:"+error);
                SetDataArray(data.toString().split(","));
            }
    })
  
    if ( (data !== undefined && data !== null) && arr == null) {
        console.log("DATA:"+data.toString().split(","));
        console.log("Set Array:"+data.toString().split(","));
        SetDataArray(data.toString().split(","));
        console.log("After Set:"+arr);
    }

    function createData(name, amt) {
        return { name, amt };
      }
          /*
    console.log("DATA:"+data);
    console.log("ERROR:"+error);
    console.log("isConnected:"+isConnected); 
    */

    return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400, width: '50%' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Tax</StyledTableCell>
            <StyledTableCell align="right">%</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.amt}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}