import { useContractReads, useContractRead, useAccount } from 'wagmi'
import tokABI from './erc20abi.json' 
import { useState } from 'react';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#e84b12',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffe0d5',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function formatData(d) {
    let x = parseInt(d);
    let y = parseFloat(x/10000000000000000);
    return y;
}

function formatBTC(d) {
    let x = parseInt(d);
    let y = parseFloat(x/100000000);
    return y;
}

function Data() {

    const [arr, SetDataArray] = useState(null);
    const {isConnected} = useAccount();
    const rows = arr==null ? [] : [

            createData('Liquidity Fee', formatData(arr[5].toString())),
            createData('Marketing Fee', formatData(arr[6].toString())),
            createData('Token Rewards Fee', formatData(arr[7].toString())),        
            
            createData('Buy Dead Fee', arr[10].toString()+ "%"),            
            createData('Buy Liquidity Fee', arr[0].toString()+ "%"),
            createData('Buy Marketing Fee', arr[1].toString()+ "%"),
            createData('Buy Token Reward Fee', arr[11].toString()+ "%"),            
            
            createData('Sell Dead Fee', arr[12].toString()+ "%"),
            createData('Sell Liquidity Fee', arr[3].toString()+ "%"),
            createData('Sell Marketing Fee', arr[4].toString()+ "%"),
            createData('Sell Token Rewards Fee', arr[13].toString()+ "%"),            
            
            createData('Number Of Dividend Token Holders', arr[8].toString()),
            createData('Total Dividends Distributed', formatBTC(arr[9].toString()) + " BTC"),            
      ];

    const { data, error, isError, isLoading } = useContractReads({
        contracts: [
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'buyLiquidityFee',
                chainId: 250,
                //0
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'buyMarketingFee',
                chainId: 250,
                //1
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'getClaimWait',
                chainId: 250,
                //2
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'sellLiquidityFee',
                chainId: 250,
                //3
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'sellMarketingFee',
                chainId: 250,
                //4
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'AmountLiquidityFee',
                chainId: 250,
                //5
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'AmountMarketingFee',
                chainId: 250,
                //6
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'AmountTokenRewardsFee',
                chainId: 250,
                //7
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'getNumberOfDividendTokenHolders',
                chainId: 250,
                //8
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'getTotalDividendsDistributed',
                chainId: 250,
                //9
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'buyDeadFee',
                chainId: 250,
                //10
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'buyTokenRewardsFee',
                chainId: 250,
                //11
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'sellDeadFee',
                chainId: 250,
                //12
            },
            {
                address: '0x4496B1D72B1B18AF61702733077Cf9f5B07C160e',
                abi: tokABI,
                functionName: 'sellTokenRewardsFee',
                chainId: 250,
                //13
            },                        
            
            ],
            onSettled(data, error) {
                console.log("DATA*:"+data);
                console.log("ERROR*:"+error);
                SetDataArray(data.toString().split(","));
            }
    })
  
    if ( (data !== undefined && data !== null) && arr == null) {
        console.log("Set Array");
        SetDataArray(data.toString().split(","));
        console.log(arr);
    }
    
    function createData(name, amt) {
        return { name, amt };
    }

    console.log("DATA:"+data);
    console.log("ERROR:"+error);
    console.log("isConnected:"+isConnected); 

    return (
        <div>

        { arr !== null ?
            <div>
                <TableContainer component={Paper}>
                <Table sx={{ width: 285 }} aria-label="customized table">
                    <TableHead>
                    <TableRow>
                        <StyledTableCell>Tax</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                            {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.amt}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
                :
        <div>    
            Unable to retrieve data at this time.
        </div>
    }  
        </div>
    )
  }

  export default Data;